/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

@import '../theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &.basic-tout {
      &__wrapper {
        display: flex;
        flex-direction: column;
      }
      &__content-above-block {
        flex: 0;
      }
      &__content-below-block {
        flex: 1;
        margin-bottom: 30px;
        .elc-button.elc-media-asset {
          padding: 0 100px;
          @include breakpoint($large-up) {
            padding: 0 30px;
          }
        }
      }
    }
    .basic-tout__content-over-media {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  &__line {
    display: block;
    p {
      margin: 0;
      padding: 0;
    }
    .banner-offer {
      &__eyebrow {
        position: absolute;
        top: 10%;
      }
      &__headlines {
        @include breakpoint($medium-up) {
          position: absolute;
          bottom: 30%;
          #{$ldirection}: 5%;
        }
        @include breakpoint($large-up) {
          #{$ldirection}: 7%;
        }
      }
      &__content {
        @include breakpoint($medium-up) {
          position: absolute;
          bottom: 25%;
          #{$ldirection}: 8%;
        }
        @include breakpoint($large-up) {
          #{$ldirection}: 12%;
        }
      }
    }
  }
  &__links {
    display: block;
    min-height: 1.5em;
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
